import "./styles.css";
import VideoCover from "react-video-cover";

export default function App() {
  return (
    <div className="app">
      <div className="content">
        <img width="60%" src="sohyle_logo.png" alt="Sohyle Logo" />
      </div>
      <div className="gradient-overlay"></div>
      <div className="blur"></div>
      <div className="vignette"></div>
      <VideoCover
        videoOptions={{
          autoPlay: true,
          muted: true,
          loop: true,
          playsInline: true,
          className: "video",
          src: "sohyle_crops.mp4",
          ref: (videoRef) => {
            videoRef.playbackRate = 0.75;
          }
        }}
      />
    </div>
  );
}
